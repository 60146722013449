/**
 * Fetch back-end pick lists and define front-end pick lists
 */

import { months } from './components/internationalization';
import type { PickList, PickListItem, Tables } from './datamodel';
import type { SerializedResource, TableFields } from './datamodelutils';
import { f } from './functools';
import type { SpecifyResource } from './legacytypes';
import { commonText } from './localization/common';
import { formsText } from './localization/forms';
import { hasToolPermission } from './permissions';
import { createPickListItem, PickListTypes } from './picklistmixins';
import { schema } from './schema';
import type { IR, R, RA } from './types';

let pickLists: R<SpecifyResource<PickList> | undefined> = {};

// Unsafe, because pick lists might not be defined yet
export const unsafeGetPickLists = (): typeof pickLists => pickLists;

const agentTypes = [
  formsText('organization'),
  formsText('person'),
  formsText('other'),
  formsText('group'),
] as const;

const pickListTypes = [
  formsText('userDefinedItems'),
  formsText('entireTable'),
  formsText('fieldFromTable'),
] as const;

const auditLogActions = [
  formsText('insert'),
  commonText('update'),
  commonText('delete'),
  formsText('treeMerge'),
  formsText('treeMove'),
  formsText('treeSynonymize'),
  formsText('treeUnsynonymize'),
] as const;

const pickListSortTypes = [
  commonText('none'),
  commonText('title'),
  commonText('ordinal'),
];

const userTypes = ['Manager', 'FullAccess', 'LimitedAccess', 'Guest'] as const;

function definePicklist(
  name: string,
  items: RA<SerializedResource<PickListItem>>
): SpecifyResource<PickList> {
  const pickList = new schema.models.PickList.Resource();
  pickList.set('name', name);
  pickList.set('readOnly', true);
  pickList.set('isSystem', true);
  pickList.set('type', PickListTypes.ITEMS);
  pickList.set('timestampCreated', new Date().toJSON());
  pickList.set('pickListItems', items);
  return pickList;
}

export const pickListTablesPickList = f.store(() =>
  definePicklist(
    '_TablesByName',
    Object.values(schema.models).map(({ name, label }) =>
      createPickListItem(name.toLowerCase(), label)
    )
  )
);

export const monthsPickList = f.store(() =>
  definePicklist(
    '_Months',
    months.map((title, index) =>
      createPickListItem((index + 1).toString(), title)
    )
  )
);

/**
 * Create front-end only pick lists
 *
 * @remarks
 * These have to be defined inside of a function rather than globally
 * because they depend on the data model being loaded
 *
 * Since pick list names must be unique, front-end only pick lists are prefixed
 * with "_". Since these pick lists are front-end only, it is safe to rename
 * them by simply replacing the usages in code if there are any.
 *
 * If a given field has a pick list assigned to it using the schema config,
 * that pick lists would take precedence
 *
 */
export const getFrontEndPickLists: () => {
  readonly [TABLE_NAME in keyof Tables]?: {
    readonly [FIELD_NAME in TableFields<
      Tables[TABLE_NAME]
    >]?: SpecifyResource<PickList>;
  };
} = f.store(() => {
  const fullNameDirection = definePicklist('_fullNameDirection', [
    createPickListItem('-1', formsText('reverse')),
    createPickListItem('1', formsText('forward')),
  ]);

  // Like pickListTablesPickList, but indexed by tableId
  const tablesPickList = definePicklist(
    '_Tables',
    Object.values(schema.models).map(({ tableId, label }) =>
      createPickListItem(tableId.toString(), label)
    )
  );

  const frontEndPickLists = {
    Agent: {
      agentType: definePicklist(
        '_AgentTypeComboBox',
        agentTypes.map((title, index) =>
          createPickListItem(index.toString(), title)
        )
      ),
    },
    SpAuditLog: {
      action: definePicklist(
        '_AuditLogAction',
        auditLogActions.map((title, index) =>
          createPickListItem(index.toString(), title)
        )
      ),
      tableNum: tablesPickList,
      parentTableNum: tablesPickList,
    },
    PickList: {
      type: definePicklist(
        '_PickListType',
        pickListTypes.map((title, index) =>
          createPickListItem(index.toString(), title)
        )
      ),
      tableName: pickListTablesPickList(),
      sortType: definePicklist(
        '_PickListSortType',
        pickListSortTypes.map((title, index) =>
          createPickListItem(index.toString(), title)
        )
      ),
    },
    SpecifyUser: {
      userType: definePicklist(
        '_UserType',
        userTypes.map((title) => createPickListItem(title, title))
      ),
    },
    GeographyTreeDef: { fullNameDirection },
    GeologicTimePeriodTreeDef: { fullNameDirection },
    LithoStratTreeDef: { fullNameDirection },
    StorageTreeDef: { fullNameDirection },
    TaxonTreeDef: { fullNameDirection },
    PrepType: {
      name: definePicklist('_PrepType', [])
        .set('type', PickListTypes.FIELDS)
        .set('tableName', 'preptype')
        .set('fieldName', 'name'),
    },
  };

  pickLists = {
    ...pickLists,
    ...Object.fromEntries(
      Object.values(frontEndPickLists)
        .flatMap((entries) => Object.values(entries))
        .map((pickList) => [pickList.get('name'), pickList] as const)
    ),
  };

  return frontEndPickLists;
});

export const fetchPickLists = f.store(
  async (): Promise<IR<SpecifyResource<PickList>>> =>
    (hasToolPermission('pickLists', 'read')
      ? f.var(
          new schema.models.PickList.LazyCollection({
            filters: {
              domainfilter: true,
            },
          }),
          async (collection) => collection.fetch({ limit: 0 })
        )
      : Promise.resolve({ models: [] })
    ).then(async ({ models }) => {
      getFrontEndPickLists();
      pickLists = {
        ...pickLists,
        ...Object.fromEntries(
          models
            .map((pickList) =>
              pickList.get('type') === PickListTypes.ITEMS
                ? pickList
                : pickList.set('pickListItems', [])
            )
            .map((pickList) => [pickList.get('name'), pickList] as const)
        ),
      };
      return pickLists as IR<SpecifyResource<PickList>>;
    })
);
